a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar a {
  font-weight: bold;
  font-size: 1.5em;
}

.navbar-brand, .nav-link, .nav-item a:focus {
  color: white !important;
}

.nav-item a:hover {
  color: #8D8D8D !important;
}

@media (max-width: 480px) {
  .custom-navbar-toggler {
    display: normal;
  }
}

@media (min-width: 481px) {
  .custom-navbar-toggler {
    display: none;
  }
}

.header-icon {
  margin: 0 0.3em 0.15em 0;
}
