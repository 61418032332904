html {
    background-color: #26293a;
}

footer {
    color: white;
}

.footer-phone-number {
    text-decoration: none !important;
    color: white !important;
}

.body-wrapper {
    max-width: 100%;
    height: 85vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    overflow-x: hidden;
    overflow-y: scroll;
}

.body-container {
    max-width: 86.5vw;
    margin: 1em 2em;
}

.body-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 40px;
}

.content-container {
    background-color: white;
    border: 2px solid #26293a;
    opacity: 0.90;
    border-radius: 1.5em;
    color: #26293a;
    padding: 1em 1.5em 1.5em 1.5em;
}

.phone-number, .phone-number:hover {
    text-decoration: none !important;
    color: #252626;
}

.list-group-item-light {
  color: #1E1E22 !important;
}